import React, { useState } from 'react';
import "../css/register.css"
import logoBlue from "../../resources/images/suff-ai-logo-nobg.png";
import qrCode4 from "../../resources/images/qr-code-4.png";

function Register({ registerOpen, toggleRegister2 }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [registrationSuccess, setRegistrationSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        let userData = {}

        userData.name = name;
        userData.email = email;
        userData.phone = phone;

        console.log(userData);

        try {
            const response = await fetch('backend/server.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userData }),
            });

            if (response.ok) {
                console.log('Registration successful');
                setRegistrationSuccess(true);
            } else {
                console.error('Registration failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const qrCodeImgActive = () => {
        if (window.innerWidth < 868) {
            window.location.href = "https://wa.me/918050164124";
        }
    }


    return (
        <>
            <div className={`loginFormContainer ${registerOpen ? 'loginFormContainerOpen' : ''}`}>
                {!registrationSuccess && (
                    <div className="loginForm">

                        <span id='loginFormClose' onClick={toggleRegister2}>X</span>

                        <div className="loginForm-logo">
                            <a href="/">
                                <img src={logoBlue} alt="logo" />
                            </a>
                        </div>
                        <div className="loginForm-header">
                            <h1>Register</h1>
                            <div>
                                Register now to get access to a special<strong> limited period discount!</strong><br />
                                (We won’t spam you don’t worry)
                            </div>
                        </div>
                        <form className="loginForm-form" onSubmit={handleSubmit}>
                            <div className="form-item">
                                <span className="form-item-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                        <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" />
                                    </svg>
                                </span>
                                <input type="text" placeholder="Enter name" id="regNameForm" name="name" value={name} onChange={(e) => setName(e.target.value)} autoFocus required />
                            </div>
                            <div className="form-item">
                                <span className="form-item-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                        <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z" />
                                    </svg>
                                </span>
                                <input type="email" placeholder="Enter email" id="regEmailForm" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </div>
                            <div className="form-item">
                                <span className="form-item-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                        <path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z" />
                                    </svg>
                                </span>
                                <input type="tel" placeholder="Enter phone number" id="regPhoneForm" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                            </div>
                            <button type="submit">Register</button>
                        </form>

                    </div>
                )}

                {/* Registration Success Message */}
                {registrationSuccess && (
                    <div className="loginFormSuccess-popup">
                        <div className='loginFormSuccess-popup-container'>
                            <span id='loginFormSuccess-popup-close' onClick={toggleRegister2}>X</span>
                            <p>Congratulations, You are now eligible for free pickup and delivery! Scan or click the QR Code below to avail the offer:</p>
                            <img src={qrCode4} alt="" onClick={qrCodeImgActive} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Register;
