import React, { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ScrollToTopOnPageChange from './components/pages/ScrollToTopOnPageChange';
import NavBar from './components/pages/include/NavBar';
import Footer from './components/pages/include/Footer';
import QrCode from './components/pages/include/QrCode';
import Home from './components/pages/Home';
import About from './components/pages/About';
import PlaceOrder from './components/pages/PlaceOrder';
import PartnerWithUs from './components/pages/PartnerWithUs';
import Register from './components/pages/Register';
import Terms from './components/pages/Terms';
import Privacy from './components/pages/Privacy';

function App() {
  const [registerOpen, setRegisterOpen] = useState(false);
  const location = useLocation();

  const toggleRegister = () => {
    setRegisterOpen(true);
  };

  const toggleRegister2 = () => {
    setRegisterOpen(false);
  };

  return (
    <>
      <ScrollToTopOnPageChange />
      <Register registerOpen={registerOpen} toggleRegister2={toggleRegister2} />
      <NavBar toggleRegister={toggleRegister} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/placeorder" element={<PlaceOrder />} />
        <Route path="/partner" element={<PartnerWithUs />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      {location.pathname !== '/terms' && location.pathname !== '/privacy' && <QrCode />}
      <Footer />
    </>
  );
}

export default App;
