import React from 'react'
import "../css/terms.css"

function Privacy() {
    return (
        <>

            <section className='terms-page'>
                <div className='terms-page-container'>

                    <div className='terms-page-heading section-heading'>
                        <h1>PRIVACY POLICY</h1>
                        <p>
                            Thank you for choosing Suff.ai. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our website and laundry services.
                        </p>
                    </div>

                    <div className='terms-page-body'>

                        <h3>
                            Information We Collect
                        </h3>
                        <ul>
                            <li>
                                Personal Information: When you use our services, we may collect personal information such as your name, email address, phone number, billing address, and payment details.
                            </li>
                            <li>
                                Usage Information: We collect non-personal information about your interactions with our website and services, including IP address, browser type, device information, and usage patterns.
                            </li>
                        </ul>

                        <h3>
                            How We Use Your Information
                        </h3>
                        <ul>
                            <li>
                                Service Provision: We use your personal information to fulfill orders, process payments, and provide laundry services to you.
                            </li>
                            <li>
                                Communication: We may use your contact information to communicate with you about your orders, provide customer support, and send promotional offers or updates.
                            </li>
                            <li>
                                Improvement of Services: We may analyze usage data to improve our website, services, and customer experience.
                            </li>
                        </ul>

                        <h3>
                            Data Security
                        </h3>
                        <ul>
                            <li>
                                We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
                            </li>
                            <li>
                                Despite our efforts to safeguard your personal information, we cannot guarantee its absolute security. You acknowledge that the transmission of information over the internet is inherently insecure.
                            </li>
                        </ul>

                        <h3>
                            Sharing Your Information
                        </h3>
                        <ul>
                            <li>
                                We do not sell, trade, or rent your personal information to third parties without your consent, except as required by law or in compliance with legal processes.
                            </li>
                            <li>
                                We may share your information with trusted third-party service providers who assist us in operating our website, conducting our business, or servicing you.
                            </li>
                        </ul>

                        <h3>
                            Cookies and Tracking Technologies
                        </h3>
                        <ul>
                            <li>
                                We use cookies and similar tracking technologies to enhance your browsing experience, analyze website traffic, and personalize content.
                            </li>
                            <li>
                                You have the option to manage cookies through your browser settings. However, disabling cookies may affect your ability to access certain features of our website.
                            </li>
                        </ul>

                        <h3>
                            Your Choices
                        </h3>
                        <ul>
                            <li>
                                You have the right to access, update, or delete your personal information by contacting us.
                            </li>
                            <li>
                                You may choose to opt out of receiving promotional emails from us by following the instructions provided in our communications.
                            </li>
                        </ul>

                        <h3>
                            Children's Privacy
                        </h3>
                        <p>
                            Our services are not directed to children under the age of 13. We do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us.
                        </p>

                        <h3>
                            Changes to This Policy
                        </h3>
                        <p>
                            We reserve the right to update or modify this Privacy Policy at any point of time. Any changes will be effective immediately upon posting on our website.
                        </p>

                        <h3>
                            Contact Us
                        </h3>
                        <p>
                            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at suffai2023@gmail.com or Whatsapp/call +917022952746.
                            By using our website and services, you consent to the collection and use of your information as described in this Privacy Policy.

                        </p>

                    </div>

                </div>
            </section>

        </>
    )
}

export default Privacy