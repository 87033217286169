import React from 'react'
import "../css/terms.css"

function Terms() {
    return (
        <>

            <section className='terms-page'>
                <div className='terms-page-container'>

                    <div className='terms-page-heading section-heading'>
                        <h1>TERMS AND CONDITIONS</h1>
                        <p>
                            These Terms and Conditions ("Terms") govern your use of the Suff.ai website and laundry services ("Services") provided by Suff.ai. By accessing or using our website and services, you agree to be bound by these Terms.
                        </p>
                    </div>

                    <div className='terms-page-body'>

                        <h3>
                            Service Description
                        </h3>
                        <ul>
                            <li>
                                Suff.ai provides an online platform that connects users with laundry service providers ("Partners") for the pickup, cleaning, and delivery of laundry items.
                            </li>
                            <li>
                                Suff.ai will make every reasonable and possible effort to give the best service. However, Suff.ai acts as an intermediary and is not responsible for the quality of services provided by Partners.
                            </li>
                        </ul>

                        <h3>
                            Pickup and Delivery
                        </h3>
                        <ul>
                            <li>
                                The delivery representative has the right to thoroughly examine the garment(s)/item(s) and inquire about any existing damage(s)/defect(s) before collecting and accepting the garment(s)/item(s). The customer is required to cooperate with the same.
                            </li>
                            <li>
                                The Customer is advised to verify the Delivery representative Identity Card before handing them over their garment(s)/item(s). The Customer can also call Suff.ai at +917022952746 to verify the delivery representative sent by Suff.ai failing which Suff.ai shall not be responsible for any loss or damage caused to the garments.
                            </li>
                            <li>
                                Digital receipt of the pick-up garments is shared with the customer.
                            </li>
                            <li>
                                As per your service request for the clothes/items, the delivery representative to hand over the garments to the Service provider selected by you.
                            </li>
                            <li>
                                The Customer shall ensure that no valuables/belongings is/are handed over along with the garment(s)/item(s). Suff.ai is not responsible for loss of or damage to any valuable or personal or non-cleanable items left in the garment(s)/item(s) such as money, jewelry, or anything else.
                            </li>
                            <li>
                                Garment(s)/item(s) shall be delivered on the scheduled date of delivery. However, any delay in delivery due to reasons, including but not limited, to the occurrence of a Force Majeure event such as fire, flood, storm, natural calamities, earthquake, enemies, war, riot, civil commotion or any other irresponsible force, act of God which are beyond the control of Suff.ai, does not entitle the Customer for any compensation/discount.
                            </li>
                            <li>
                                Express Service/Urgent delivery/Premium service shall be charged at applicable rates and the Customer is required to check the same at the respective Service provider before availing such services.
                            </li>
                            <li>
                                The Customer shall take delivery of the processed garment(s)/ item(s) as per the delivery date. Failing which the garment(s)/item(s) shall be sent back to the processing unit and delivery of the same thereafter shall be subject to additional charges as per the discretion of Suff.ai. The Customer shall produce the Order Number while taking back delivery of the processed garment(s)/item(s). Suff.ai shall not be responsible/accountable for garment(s)/item(s) not claimed within the said period of three (3) days from the date of delivery.
                            </li>
                            <li>
                                The Customer shall examine the garment(s)/ item(s) for wrong delivery/missing item(s) at the time of delivery and inform the delivery representative about the same at that time. Suff.ai shall not be responsible for any claims after the garment(s)/ item(s) delivery has been accepted by the Customer.
                            </li>
                            <li>
                                Garment(s)/item(s) are assigned to undergo wet wash or dry cleaning (whichever is best suitable for the said garment(s)/item(s)) after the Service provider analysis of the type and texture of the garment(s)/item(s) in the processing unit. If the Customer wants any specific type of wash or cleaning then the same should be informed before availing the service.
                            </li>
                            <li>
                                Any offers/coupons provided by Suff.ai can be redeemed/claimed within the validity period and only upon the customer’s request at the time of booking the pick-up/creating the order. In addition to these terms and conditions mentioned herein, the terms and conditions provided in such offers/coupons shall also apply and it shall be deemed that Customer has read and understood it before availing offers/coupons.
                            </li>
                        </ul>

                        <h3>
                            User Accounts
                        </h3>
                        <ul>
                            <li>
                                To use our Services, you may be required to create an account and provide certain information.
                            </li>
                            <li>
                                You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
                            </li>
                            <li>
                                You agree to provide accurate, current, and complete information when creating your account and to update your information promptly if there are any changes.
                            </li>
                        </ul>

                        <h3>
                            Orders and Payments
                        </h3>
                        <ul>
                            <li>
                                By placing an order through our platform, you agree to pay the specified fees for the requested services.
                            </li>
                            <li>
                                Payments are processed securely through our payment gateway. We do not store or have access to your payment information.
                            </li>
                            <li>
                                Prices for services may vary based on factors such as location, service type, and quantity of items.
                            </li>
                        </ul>

                        <h3>
                            Cancellations and Refunds
                        </h3>
                        <ul>
                            <li>
                                You may cancel an order within a specified timeframe before the scheduled pickup time. Cancellation fees may apply.
                            </li>
                            <li>
                                Refunds for cancellations or service issues are subject to our Refund Policy, mentioned below in this document.
                            </li>
                        </ul>

                        <h3>
                            User Conduct
                        </h3>
                        <ul>
                            <li>
                                You agree to use our website and services for lawful purposes only and to comply with all applicable laws and regulations.
                            </li>
                            <li>
                                You may not use our platform to engage in any activity that is unlawful, fraudulent, or harmful to others.
                            </li>
                        </ul>

                        <h3>
                            Intellectual Property
                        </h3>
                        <ul>
                            <li>
                                All content, trademarks, and intellectual property rights on our website are owned by Suff.ai or its licensors.
                            </li>
                            <li>
                                You may not use, reproduce, modify, or distribute any content from our website without prior written consent.
                            </li>
                        </ul>

                        <h3>
                            Limitation of Liability
                        </h3>
                        <ul>
                            <li>
                                Suff.ai shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our website or services.
                            </li>
                            <li>
                                In no event shall our total liability exceed the amount paid by you for the services in question.
                            </li>
                        </ul>

                        <h3>
                            Indemnification
                        </h3>
                        <p>
                            You agree to indemnify and hold Suff.ai and its affiliates, partners, and employees harmless from any claims, losses, damages, liabilities, or expenses arising out of your use of our website or services.
                        </p>

                        <h3>
                            Changes to Terms
                        </h3>
                        <p>
                            Suff.ai reserves the right to update or modify these Terms at any time. Any changes will be effective immediately upon posting on our website.
                        </p>

                        <h3>
                            Governing Law
                        </h3>
                        <p>
                            These Terms shall be governed by and construed as per the laws of Bengaluru Jurisdiction, without regard to its conflict of law provisions.
                        </p>

                        <h3>
                            Contact Us
                        </h3>
                        <p>
                            If you have any questions or concerns about these Terms, please contact us at suffai2023@gmail.com or Whatsapp/call +917022952746.
                        </p>

                        <p>
                            <strong>
                                By using our website and services, you agree to be bound by these Terms and Conditions.
                            </strong>
                        </p>

                    </div>

                    <div className='terms-page-heading section-heading refund-body'>
                        <h1>REFUND POLICY</h1>
                    </div>

                    <div className='terms-page-body'>

                        <h3>
                            Raising of queries by the customers:
                        </h3>
                        <p>
                            In case of occurrences of incidents wherein a defective/damaged product or a product that does not comply with the specifications mentioned originally in the order is received, you are required to inform us regarding the same on our contact number +917022952746 along with a supporting photograph within 24 hours of delivery of the product(s), failing which no query shall be entertained by Suff.ai any further.
                        </p>

                        <h3>
                            Addressal of the queries raised:
                        </h3>
                        <p>
                            Upon receiving your complaint, we shall verify the authenticity and nature of the complaint by scrutinizing the supporting photographs and information provided by the customer and checking the same with the concerned service provider.
                        </p>

                        <h3>
                            Eligibility for Refund:
                        </h3>
                        <p>
                            The Customer is eligible for a refund by Suff.ai  which in all cases, shall not exceed the bill amount or Rs. 1000/- (Rupees One Thousand Only), whichever is lower irrespective of the cost of the garments, provided the complaint is raised within the specified time and the product is in an unused condition.
                            All payments as mentioned herein shall only be in Indian Rupees.
                        </p>

                        <h3>
                            Refund Process:
                        </h3>
                        <ul>
                            <li>
                                After successful verification and validation of your complaint with the Suff.ai team and the concerned service provider, the refund shall be initiated within 7-10 working days from the date when the query has been resolved by our laundry to the original payment method/account of the customer.
                            </li>
                            <li>
                                Orders paid through either UPI or cash on delivery payment modes will be refunded within 7-21 working days to the same UPI account, depending on the circumstances.
                            </li>
                            <li>
                                The arrangement for the pick-up of the defective/damaged product(s) will be done by our logistics partner. In case of our inability to do so, we will notify you of the same and you will be required to dispatch the product through any courier service in your area within one day of receipt of such notice. The courier service charges will be reimbursed by us only upon prior consultation with us regarding the same.
                            </li>
                        </ul>

                        <h3>
                            Exclusions:
                        </h3>
                        <p>
                            Suff.ai shall not be held responsible/liable for any loss(s) or damage(s) of/to garment(s) due to occurrence of force majeure event such as fire, flood, storm, natural calamities, earthquake, enemies, war, riot, civil commotion, lock-down or any other irresponsible force, act of God which are beyond the control of Suff.ai, and Suff.ai is not liable for any compensation/damages in any such case.
                        </p>

                        <h3>
                            Prevention and Care:
                        </h3>
                        <p>
                            By availing the services provided by Suff.ai, the customer agrees that he/she has thoroughly read, understood, and agreed to this refund policy.
                        </p>

                        <h3>
                            Changes to Refund Policy:
                        </h3>
                        <p>
                            Suff.ai reserves the right to update or modify this refund policy at any point of time.
                            Changes to the policy will be effective immediately upon posting on our website.

                        </p>

                        <h3>
                            Contact Us:
                        </h3>
                        <p>
                            If you have any questions about our refund policy or need assistance, please contact our customer support team at suffai2023@gmail.com or Whatsapp/call +917022952746.
                            By using our services, you agree to comply with and be bound by this specific refund policy for damage or loss.
                        </p>

                        <p>
                            <strong>
                                Thank you for choosing Suff.ai!
                            </strong>
                        </p>

                    </div>

                </div>
            </section>

        </>
    )
}

export default Terms