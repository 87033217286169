export default function applyIPhoneBackgroundStyles() {
    // Detect if the user agent string indicates an iPhone
    const userAgent = navigator.userAgent;
    const isIPhone = /iPhone|iPod/.test(userAgent);
    const isIPad = /iPad/.test(userAgent);

    if (isIPhone) {
        document.querySelector('.main-section.first-section').classList.add('iphone');
    }
    if (isIPad) {
        document.querySelector('.main-section.first-section').classList.add('ipad');
    }
}