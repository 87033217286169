import React, { useRef, useEffect } from 'react';
import applyIPhoneBackgroundStyles from '../js/iphoneBackgroundStyles';
import { Link } from "react-router-dom";
import "../css/placeOrder.css"
import howItWorksFeatImg from "../../resources/images/roadmap.png"
import locationPin from "../../resources/images/map-marker.png"

function PlaceOrder() {

    useEffect(() => {
        applyIPhoneBackgroundStyles();
    }, []);

    return (

        <>

            <section className='main-section first-section placeOrder-hero-section page-hero-section'>
                <div className='overlay'></div>
                <div className='section-container'>

                    <h3 className='placeOrder-hero-section-heading page-hero-section-heading'>
                        Place an order with us.
                    </h3>

                </div>
            </section>

            <section className='main-section second-section howItWorks-section'>
                <div className='section-container'>

                    <h1 className='howItWorks-section-heading section-heading'>
                        How it works
                    </h1>

                    <div className='howItWorks-content'>

                        <div className='howItWorks-content-feature-image'>
                            <img src={howItWorksFeatImg} alt="" />
                        </div>

                        <div className='howItWorks-content-card howItWorks-content-card-1'>
                            <div className='howItWorks-content-icon howItWorks-content-icon-1'>
                                <img src={locationPin} alt="" />
                                <span>1</span>
                            </div>
                            <div className='howItWorks-content-text howItWorks-content-text-1'>
                                <p>Scan/Click the QR code at the bottom left.</p>
                            </div>
                        </div>

                        <div className='howItWorks-content-card howItWorks-content-card-2'>
                            <div className='howItWorks-content-icon howItWorks-content-icon-2'>
                                <img src={locationPin} alt="" />
                                <span>2</span>
                            </div>
                            <div className='howItWorks-content-text'>
                                <p>Choose the right service provider.</p>
                            </div>
                        </div>

                        <div className='howItWorks-content-card howItWorks-content-card-3'>
                            <div className='howItWorks-content-icon howItWorks-content-icon-3'>
                                <img src={locationPin} alt="" />
                                <span>3</span>
                            </div>
                            <div className='howItWorks-content-text howItWorks-content-text-3'>
                                <p>Choose the service(s) you wish to avail.</p>
                            </div>
                        </div>

                        <div className='howItWorks-content-card howItWorks-content-card-4'>
                            <div className='howItWorks-content-icon howItWorks-content-icon-4'>
                                <img src={locationPin} alt="" />
                                <span>4</span>
                            </div>
                            <div className='howItWorks-content-text'>
                                <p>Schedule the pick up as per convenience.</p>
                            </div>
                        </div>

                        <div className='howItWorks-content-card howItWorks-content-card-5'>
                            <div className='howItWorks-content-icon howItWorks-content-icon-5'>
                                <img src={locationPin} alt="" />
                                <span>5</span>
                            </div>
                            <div className='howItWorks-content-text howItWorks-content-text-5'>
                                <p>The most important step… Now Relax!</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

        </>
    )
}

export default PlaceOrder