import React, { useState, useEffect } from 'react';
import "../../css/qrCode.css"
import qrCode from "../../../resources/images/QR-code.jpg"

function QrCode() {

  const [qrImage, setQrImage] = useState(false)
  const [isQrCode, setIsQrCode] = useState(false)
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const qrCodeImgActive = () => {
    if (window.innerWidth < 868) {
      window.location.href = "https://wa.me/918050164124";
    } else {
      setQrImage(prevState => !prevState);
      setIsQrCode(prevState => !prevState);
    }
  }

  return (
    <div className={qrImage ? 'qrCode qrCode-hide' : 'qrCode'} onClick={qrCodeImgActive}>
      <div className={qrImage ? 'qrCode-img qrCodeImg-active' : 'qrCode-img'}>
        <img src={qrCode} alt="" />
      </div>
      <p className='qrCodeText'>Scan or click to browse and place order</p>
      <p className={scrolled ? 'qrCodeBonceText qrCodeBonceTextWhite' : 'qrCodeBonceText'}>⟵ Hover over me</p>
    </div>
  )
}

export default QrCode;